.container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.pageContents {
    width: calc(100% - 100px);
    max-width: 1292px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
}

.sectionTitle {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #FFFFFF;
}

.sectionContents {
    display: flex;
    flex-direction: row;
}
    
@media only screen and (max-width: 1200px) {
    .pageContents {
        width: calc(100% - 40px);
        padding: 20px;
    }
}

@media only screen and (max-width: 350px) {
    .pageContents {
        width: calc(100% - 20px);
        padding: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .section {
        margin-top: 40px;
    }

    .sectionTitle {
        font-size: 28px;
        line-height: 32px;
    }
}

@media only screen and (max-width: 500px) {
    .section {
        margin-top: 20px;
    }

    .sectionTitle {
        font-size: 24px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 300px) {
    .sectionTitle {
        font-size: 16px;
        line-height: 20px;
    }
}