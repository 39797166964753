.container {
    display: flex;
    flex-direction: column;
    background: #1E1E26;
    border-radius: 12px;
    margin-bottom: 18px;
    cursor: pointer;
}

.topBar {
    display: flex;
    align-items: center;
    flex-direction: row;
    transition: 0.15s;
    min-height: 64px;
    overflow: hidden;
    border-radius: var(--b-radius-md);

    &:hover {
        background: var(--c-orange);

        .expandIconContainer {
            background: var(--c-panel);
        }

        .expandIcon {
            fill: var(--c-white);
        }

        .itemTitle {
            color: var(--c-black);
        }
    }
}

.itemTitle {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: var(--c-white);
    margin-left: 20px;
}

.expandIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #282833;
    border-radius: var(--b-radius-md);
    margin-right: 15px;
    margin-left: 10px;
    width: 36px;
    height: 36px;
}

.expandIcon {
    width: 60%;
    height: 60%;
    fill: #88869D;
    transition: fill, transform 0.15s;
}

/* .expandIcon:hover {
    fill: white;
} */

.expandIconExpanded {
    fill: white;
    transform: rotate(180deg);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 20px;
    overflow: hidden;
    transition: 0.15s ease;
}

.content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--c-white);
    margin: 7px 0;
}

.contentHidden {
    max-height: 0;
    opacity: 0;
    margin: 0 20px;
}


@media only screen and (max-width: 615px) {
    .itemTitle {
        font-size: 14px;
        line-height: 14px;
    }

    .expandIconContainer {
        width: 30px;
        height: 30px;
    }

    .content {
        font-size: 12px;
        line-height: 21px;
        margin: 3px 0;
    }
}