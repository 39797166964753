.container {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr 3fr 1.5fr 2.5fr;
    align-items: center;
    width: calc(100% - 128px);
    background: #282833;
    border-radius: 12px;
    margin: 0 24px 12px 24px;
    padding: 0 40px;
    min-height: 65px;
}

.valueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-right: 5px;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    min-height: 25px;
    width: 25px;
    height: 25px;
}

.icon {
    width: 100%;
    height: 100%;
    margin-left: 5px;
    opacity: 0.75;
    cursor: pointer;
    transition: opacity 0.15s;
}

.icon:hover {
    opacity: 1;
}

@media only screen and (max-width: 900px) {
    .container {
        grid-template-columns: 0.8fr 3fr 1.5fr 2.5fr;
    }

    .secondaryIcon {
        display: none;
    }

    .dateValue {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        grid-template-columns: 0.8fr 3fr 2.5fr;
    }

    .mintValue {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        margin: 0 10px 7px 10px;
        width: calc(100% - 60px);
        padding: 0 20px;
    }
}