.wrapper {
    margin-top: 85px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.botImage {
    align-self: flex-start;
    max-width: 100%;
    margin-top: -50px;
    animation: wobble var(--anim-duration-xl) ease-in-out alternate infinite;
    transform-origin: 50% 30%;
}

.contentsContainer {
    width: 100%;
    max-width: 1188px;
    align-self: center;
    display: flex;
    flex-direction: row;
    position: relative;
}

.leftContainer {
    flex: 0.96;
    margin-left: 50px;
    margin-right: 50px;
}

.rightContainer {
    width: 540px;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 58px;
    color: var(--c-white);
    margin: 0 0 25px 0;
}

.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--c-white);
}

@media only screen and (max-width: 1100px) {
    .contentsContainer {
        margin-top: 40px;
    }

    .rightContainer {
        margin-top: 10px;
    }

    .leftContainer {
        flex: 0.96;
        margin-left: 20px;
        margin-right: 50px;
    }

    .botImage {
        margin-right: 110px;
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 900px) {
    .contentsContainer {
        flex-direction: column;
    }

    .botImage {
        display: none;
    }

    .description {
        margin-bottom: 20px;
    }

    .rightContainer {
        width: calc(100% - 40px);
        padding: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 38px;
        line-height: 40px;
    }
    
    .description {
        font-size: 14px;
        line-height: 20px;
    }

    .leftContainer {
        flex: 0.96;
        margin-left: 10px;
        margin-right: 30px;
    }

    .description {
        margin-bottom: 30px;
    }
    
    .rightContainer {
        width: calc(100% - 20px);
        padding: 10px;
    }
}

@media only screen and (max-width: 550px) {
    .rightContainer {
        zoom: 0.9;
    }
}

@media only screen and (max-width: 490px) {
    .rightContainer {
        zoom: 0.8;
    }
}

@media only screen and (max-width: 430px) {
    .rightContainer {
        zoom: 0.7;
    }
}

@keyframes wobble {
    0% {
        transform: rotate(-20deg) translateY(-60px);
    }

    100% {
        transform: rotate(-10deg) translateY(0px);
    }
}