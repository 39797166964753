.container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.indicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
}

.indicatorLine {
    flex: 1 1;
    width: 1px;
    border-left: 2px solid #FCE8BD;
    margin-left: 2px;
}

.futureLine {
    border-left: 2px solid #3E3E51;
}

.noLine {
    border-left: none;
}

.indicatorCircle {
    width: 12px;
    height: 12px;
    border: 2px solid #FCE8BD;
    border-radius: 50%;
    margin: 10px;
}

.futureCircle {
    border: 2px solid #3E3E51;
}

.bodyContainer {
    flex: 1;
    background: 
        linear-gradient(
            180deg,             
            #171d68 0%,             
            #12185b 100%        
        );
    border-radius: var(--b-radius-lg);
    margin: 20px;
    padding: 26px 34px;
}

.futureBody {
    background: #1F1E26;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #E5E5E5;
    margin: 0 0 5px 0;
}

.titleDate {
    text-align: right;
    margin-left: 20px;
}

.paragraphContainerHidden {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s ease-in-out;
}

.paragraphContainer {
    max-height: 500px;
}

.paragraph {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    width: 100%;
    margin: 8px 0;
}

.readMoreContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.readMoreArrow {
    width: 38px;
    height: 38px;
    margin-right: 10px;
    transition: transform 0.15s;
}

.readMoreContainer:hover {
    opacity: 0.85;
}

.readMoreArrowRotated {
    transform: rotate(180deg);
}

.readMore {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}


@media only screen and (max-width: 1240px) {
    .indicatorContainer {
        width: 10px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .bodyContainer {
        margin: 10px 20px 10px 15px;
    }
    
    .indicatorContainer {
        width: 5px;
        margin-left: 3px;
    }

    .indicatorLine {
        border-left: 1px solid #FCE8BD;
        margin-left: 2px;
    }
    
    .futureLine {
        border-left: 1px solid #3E3E51;
    }
    
    .indicatorCircle {
        width: 12px;
        height: 12px;
        border: 1px solid #FCE8BD;
        border-radius: 50%;
        margin: 10px;
    }
    
    .futureCircle {
        border: 1px solid #3E3E51;
    }

    .noLine {
        border: none;
    }

    .title {
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 4px 0;
    }

    .paragraph {
        font-size: 14px;
        line-height: 18px;
        margin: 6px 0;
    }
}