.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #1E1F26;
    border-radius: var(--b-radius-lg);
    padding-bottom: 12px;
}

.headerContainer {
    width: calc(100% - 128px);
    padding: 32px 64px;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr 3fr 1.5fr 2.5fr;
}

.headerLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 0;
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    align-self: flex-end;
    margin-top: 20px;
}

.paginationPageClassName {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    list-style: none;
    border: 1px solid #323240;
    border-radius: var(--b-radius-md);
    min-width: 38px;
    height: 38px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #474758;
    margin: 0 9px;
    cursor: pointer;
    transition: border 0.15s;
}

.paginationPageClassName:hover {
    border: 1px solid var(--c-orange);
    color: white;
}

.paginationActivePageClassName {
    /* border: 1px solid var(--c-orange); */
    background: var(--c-orange);
    color: #000000;
    transition: background-color 0.15s;
}

.paginationActivePageClassName:hover {
    filter: brightness(1.2);
    color: #000000;
}

.paginationNavigation {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #959595;
    margin: 0 9px;
    cursor: pointer;
    transition: color 0.15s;
}

.paginationNavigation:hover {
    color: var(--c-orange);
}

.paginationNavigationActive {
    color: white;
    font-weight: 800;
}

.paginationBreak {
    font-family: 'Montserrat';
    color: white;
    margin: 0 10px 10px 10px;
}

.containerLoading {
    filter: blur(15px);
}

.loadingContainer {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

.loadingText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 22px;
    color: var(--c-orange);
    transition: color 0.15s;
    cursor: pointer;
}

.loader {
    width: 26px;
    margin-right: 15px;
}

@media only screen and (max-width: 900px) {
    .headerContainer {
        grid-template-columns: 0.8fr 3fr 1.5fr 2.5fr;
    }

    .dateValue {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .loadingText {
        font-size: 22px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 600px) {
    .headerContainer {
        grid-template-columns: 0.8fr 3fr 2.5fr;
    }

    .mintValue {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .headerContainer {
        width: 100%;
        grid-template-columns: 1.1fr 2fr 2.5fr;
        padding: 20px 10px;
    }
}

@media only screen and (max-width: 500px) {
    .loadingText {
        font-size: 20px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 300px) {
    .loadingText {
        font-size: 18px;
        line-height: 22px;
    }
}