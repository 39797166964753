.container {
    flex: auto 1;
    display: flex;
    flex-direction: column;
}

.header {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 35px 44px 30px 44px;
}

.title {
    flex: 1 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.headerIcon {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.8;
}

.fieldContainer {
    margin: 0 44px 13px 44px;
}

.row {
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.earned {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: 0.6;
    margin: 0 0 10px 0;
}

.secondaryLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff99;
    margin: 7px 0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 44px 34px 44px;
}

.claimButton {
    flex: 1;
    background: #251A54;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 58px;
    color: #FFFFFF;
    margin-right: 20px;
    transition: background-color 0.15s;
}

.claimButtonRed {
    background: #501461;
}

.horizontalSpacer {
    margin: 0 0 20px 0;
}

p .secondaryLabel {
    margin-left: 5px;
}