.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    background: #27241F;
    border-radius: 100px;
    height: 34px;
    cursor: pointer;
    transition: 0.15s;
}

.container:hover {
    background: #D9B76D25;
}

.containerDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    background: #D9B76D19;
    border-radius: 100px;
    height: 34px;
    opacity: 0.6;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    margin: 0 7px 0 15px;
}

.shortLabel {
    display: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    margin: 0 7px 0 15px;
}

.iconContainer {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 50%;
    margin: 5px;
}

.icon {
    width: 60%;
}

@media only screen and (max-width: 400px) {
    .label {
        display: none;
    }
    
    .shortLabel {
        display: block;
    }
}