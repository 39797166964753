:root {
	--anim-duration-xs: 100ms;
	--anim-duration-sm: 250ms;
	--anim-duration-md: 400ms;
	--anim-duration-lg: 500ms;
	--anim-duration-xl: 5000ms;

	--c-panel: rgba(40 20 40 / 75%);
	--c-orange: #e80;
	--c-magenta: #a06;
	--c-white: white;
	--c-black: black;

	--b-radius-xs: 3px;
	--b-radius-sm: 6px;
	--b-radius-md: 12px;
	--b-radius-lg: 24px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0E0E12;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

p,
img {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 4px;
	left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--c-orange);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: white;
}

@media only screen and (max-width: 350px) {
    body {
        zoom: 0.9;
    }
}

@media only screen and (max-width: 300px) {
    body {
        zoom: 0.8;
    }
}

@media only screen and (max-width: 280px) {
    body {
        zoom: 0.7;
    }
}