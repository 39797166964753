.container {
    display: flex;
    flex-direction: column;
}

.faqItemList {
    z-index: 1;
    width: calc(100% - 100px);
    max-width: 1200px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-self: center;
    opacity: 0.9;
    margin-bottom: 40px;
}

.roadmapContainer {
    width: 100%;
    max-width: 1110px;
    align-self: center;
    display: flex;
    flex-direction: column;
    border: 1px solid #323240;
    border-radius: var(--b-radius-lg);
    padding: 60px 75px;
    margin-top: 70px;
}

.roadmapTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    margin: 0 0 55px 0;
}
    
@media only screen and (max-width: 1240px) {
    .roadmapTitle {
        margin: 0 0 20px 0;
    }
    .roadmapContainer {
        margin-top: 30px;
        width: calc(100% - 10px);
        padding: 0 0 0 10px;
        border: none;
    }
}
