.container {
    flex: auto 1;
    display: flex;
    flex-direction: column;
}

.header {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 35px 44px 30px 44px;
}

.title {
    flex: 1 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.headerIcon {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.8;
}

.fieldContainer {
    margin: 20px 44px 0 44px;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contentTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #ffffffda;
    margin: 0 0 30px 0;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: 0.6;
    margin: 0 0 10px 0;
}

.stakeButton {
    width: calc(100% - 88px);
    background: #251A54;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 58px;
    color: #FFFFFF;
    margin: 0 44px 34px 44px;
}

.stakeButtonRed {
    background: #501461;
}

.stakeButton:hover {
    background: #2e2459;
}

.stakeButtonRed:hover {
    background: #521d61;
}