.container {
    width: calc(100% - 100px);
    align-self: center;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #141417;
    padding: 0 50px;
}

.logoText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    color: white;
    text-decoration: none;
}

.logoText777 {
    color: var(--c-orange);
}

.rightSideContainer {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.menuItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.verticalSpacer {
    height: 30px;
}

.menuItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140.62%;
    /* margin: 0 30px; */
    color: white;
    cursor: pointer;
    transition: color 0.15s;
    text-decoration: none;
}

.menuItem[target] {
    background: var(--c-magenta);
    padding: .2rem .6rem;
    border-radius: var(--b-radius-sm);
}

.menuItem:hover {
    filter: brightness(1.1);
}

.labeledIcon {
    height: 34px;
    margin-left: 10px;
}

.metamaskIcon {
    width: 26px;
    height: 26px;
}

.menuIcon {
    display: none;
    width: 22px;
    margin-right: 17px;
    stroke: white;
    cursor: pointer;
}

@media only screen and (max-width: 1300px) {}

@media only screen and (max-width: 1120px) {
    .container {
        width: calc(100% - 40px);
        padding: 0 20px;
    }

    .menuIcon {
        display: block;
    }

    .menuItemContainer {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .languageDropdown {
        display: none;
    }
}
