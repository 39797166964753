.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: 'transparent';
}

.video {
    max-width: 100%;
    max-height: 100%;
}

.closeIcon {
    z-index: 10;
    right: 10px;
    top: 10px;
    position: absolute;
    width: 30px;
    height: 30px;
    stroke: white;
    cursor: pointer;
}

.closeIcon:hover {
    stroke: var(--c-orange);
}