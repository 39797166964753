.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1087px;
    align-self: center;
    margin: 60px 0;
}

.botContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 40px;
    background: var(--c-orange);
    border-radius: 22px;
    padding: 10px 15px;
    width: 200px;
    margin-top: -175px;
}

.botImage {
    width: 100%;
}

.externalTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #FFFFFF;
    margin: 0 0 26px 0;
}

.externalDescription {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #88869F;   
    margin: 0 0 40px 0;
    max-width: 60%;
}

.internalContainer {
    display: flex;
    flex-direction: column;
    padding: 28px 34px;
    width: calc(100% - 68px);
    border-radius: var(--b-radius-lg);
    background-size: cover;
}

.internalTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #000000;
    margin: 0 0 18px 0;
}

.internalDescription {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #88869D; 
    margin: 0 0 32px 0;
    max-width: calc(100% - 250px);
}

.internalDescriptionLower {
    max-width: 100%;
}

@media only screen and (max-width: 830px) {
    .container {
        margin: 40px 0;
    }

    .botContainer {
        width: 25%;
        margin-top: -150px;
    }

    .internalDescription {
        max-width: calc(70% - 40px);
    }

    .internalDescriptionLower {
        max-width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .container {
        margin: 20px 0;
    }
    
    .botContainer {
        display: none;
    }

    .externalDescription {
        max-width: 100%;
    }

    .internalDescription {
        max-width: 100%;
    }
}