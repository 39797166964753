.container {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: 
        linear-gradient(
            180deg, 
            #1C163B 0%, 
            #10142D 100%
        );
    border-radius: var(--b-radius-lg);
}

.upperSpacer {
    width: 100%;
    margin-bottom: 12px;
}

.titleBarContainer {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 40px;
}

.closeIcon {
    stroke: white;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: fill 0.15s;
}

.closeIcon:hover {
    stroke: rgba(255, 255, 255, 0.7);
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.message {
    width: calc(100% - 80px);
    font-family: 'Montserrat';
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 0 40px;
    margin: 10px 0;
}

.walletButtonList {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 0 40px;
}

.howToLink {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #D3B877;
    cursor: pointer;
    margin: 15px 0 25px 0;
}

.howToLink:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 570px) {
    .container {
        width: 100%;
    }
}