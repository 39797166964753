.container {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(14px);
}

.overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(10px);
}

.menuContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #131313;
}

.menuBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 0 20px 0 15px;
    height: 64px;
    width: calc(100% - 35px);
    border-bottom: 1px solid #2323239e;
}

.logoText {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    color: white;
    text-decoration: none;
}

.languageDropdown {
    display: none;
    margin-right: 20px;
}

.logoText777 {
    color: var(--c-orange);
}

.closeIcon {
    width: 24px;
    cursor: pointer;
    fill: white;
}

.closeIcon:hover {
    fill: #FCBE01;
}

.searchInput {
    width: calc(100% - 40px);
    margin: 20px;
}

.menuItemContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.menuContainer {
    width: 374px;
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
}

.walletIcon {
    width: 22px;
    margin-left: 2px;
    margin-right: 10px;
    stroke: white;
}

@media only screen and (max-width: 768px) {
    .menuContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .languageDropdown {
        display: flex;
    }
}