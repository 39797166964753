.container {
    display: flex;
    flex-direction: column;
}

.faqItemList {
    z-index: 1;
    width: calc(100% - 100px);
    max-width: 1200px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-self: center;
    opacity: 0.9;
    margin-bottom: 40px;
}
    
@media only screen and (max-width: 1200px) {
    .faqItemList {
        width: calc(100% - 40px);
        padding: 20px;
    }
}

@media only screen and (max-width: 830px) {
    .faqItemList {
        margin-bottom: 20px;
    }
}
