.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.blockContainer {
    width: calc(100% - 68px);
    background: 
        linear-gradient(
            180deg, 
            rgba(62, 39, 158, 0.3) 0%, 
            rgba(17, 33, 107, 0.3) 100%
        );

    border-radius: var(--b-radius-lg);
    padding: 20px 34px;
    margin-bottom: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.statColumns {
    display: grid;
    grid-template-columns: 7fr 6fr 5fr;
}

.timeValueWrapper {
    flex: 1;
    display: flex;
}

.timeValue {
    flex: 1;
    align-self: flex-end;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 40px;
    color: var(--c-orange);
    margin: 0;
}

.timeLabel {
    align-self: flex-end;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0 0 0 5px;
}

.stateValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 24px 0 5px 0;
}

.stateValue span {
    font-size: 20px;
    margin: 0 0 0 -3px;
}

.stateLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 5px 0 10px 0;
}

.fieldRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 7px 0;
}

.currencyLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #A2A1AE;
    margin: 0;
}

.input {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #FFFFFF;
    background: none;
    border: none;
    outline: none;
    text-align: right;
}

.secondaryLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff99;
    margin: 0;
}

.maxLabel {
    cursor: pointer;
    color: #EBC86D;
    margin-left: 5px;
}

.buyButton {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: 25px 0 10px 0;
}

.positionLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.positionValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.bottomSpacer {
    margin: 15px 0;
}

.disclaimer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #888A97;
}

.disclaimer span {
    font-style: italic;
}

.messageOverlayContainer {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 1.3rem 0 2.5rem;
    border-radius: var(--b-radius-md);
}

.loaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.messageOverlayText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
}

.messageOverlayButton {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    height: 44px;
    width: 200px;
}

.loader {
    width: 24px;
    margin-right: 10px;
}

.blurred {
    border-color: transparent;
    filter: blur(10px) brightness(.5);
}

.saleMessageText {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #ffffffa0;
}