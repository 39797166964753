.messageContainer {
  background: #501461;
  text-align: left;
}

.container {
  margin: 0 auto;
  position: relative;
  padding: .5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.message {
  font-weight: bold;
  color: white;
}

.closeButton {
  flex: none;
  width: 3rem;
  height: 3rem;
  border: none;
  background: none;
  cursor: pointer;
}

.closeIcon {
  stroke: white;
  transition: stroke var(--anim-duration-sm);
}

.closeIcon circle {
  transition: fill var(--anim-duration-sm);
}

.closeIcon:hover {
  stroke: black;
}

.closeIcon:hover circle {
  fill: white;
}