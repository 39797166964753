.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
}

.title {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #FFFFFF;
}

.contents {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1100px) {
    .contents {
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 28px;
        line-height: 32px;
    }

    .container {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 24px;
        line-height: 26px;
    }

    .container {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 300px) {
    .title {
        font-size: 16px;
        line-height: 20px;
    }
}