.container {
    flex: auto 1;
    display: flex;
    flex-direction: column;
}

.header {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 35px 44px 30px 44px;
}

.title {
    flex: 1 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.headerIcon {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.8;
}

.fieldContainer {
    margin: 15px 44px 0 44px;
}

.inputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 44px 7px 44px;
}

.balanceRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 44px 25px 44px;
}

.sliderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 35px 44px 30px 44px;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #A2A1AE;
    margin: 0;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #FFFFFF;
    margin: 0;
}

.sliderValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #FFFFFF;
    width: 80px;
    margin: 0;
}

.secondaryLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff99;
    margin: 0;
}

.secondaryLabelHighlight {
    opacity: 1;
    color: #EBC86D;
    cursor: pointer;
}

.unstakeButton {
    width: calc(100% - 88px);
    background: #251A54;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 58px;
    color: #FFFFFF;
    margin: 0 44px;
    transition: background-color 0.15s;
    margin: 0 44px 34px 44px;
}

.unstakeButtonRed {
    background: #501461;
}

.spacer {
    margin: 5px 0 25px 0;
}

.slider {
    flex: 1 auto;
    -webkit-appearance: none;
    height: 6px;
    border-radius: 5px;
    background: #332769;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #6659a0;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #6659a0;
    cursor: pointer;
}

.sliderRed {
    background: #501461;
}

.sliderRed::-webkit-slider-thumb {
    background: #902AAD;
}

.sliderRed::-moz-range-thumb {
    background: #902AAD;
}

p .secondaryLabelHighlight {
    margin-left: 5px;
}