.container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #2323239e;
}

.container:hover .text {
    color: #FCBE01;
}

.icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin: 4px 0 1px 0;
    text-align: center;
    color: #FFFFFF;
}

.externalLink {
    padding-top: .5rem;
    padding-bottom: .5rem;

    .text {
        background: var(--c-magenta);
        padding: .8rem 1rem;
        border-radius: var(--b-radius-sm);
        width: 100%;
        margin: 0;
    }
}