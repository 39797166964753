.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.botImage {
    position: absolute;
    margin: 40px 60px 0 0;
    width: 100%;
    max-width: 1180px;
    height: 450px;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    animation: floatUpDown var(--anim-duration-xl) ease-in-out infinite;
}

.contentsContainer {
    width: 100%;
    max-width: 1188px;
    align-self: center;
    display: flex;
    flex-direction: row;
    position: relative;
}

.leftContainer {
    width: 50%;
    min-width: 400px;
    margin-left: 50px;
    margin-right: 50px;
}

.rightContainer {
    flex: 1;
}

.botsImage {
    width: 100%;
    margin-top: 80px;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 58px;
    color: #FFFFFF;
    margin: 85px 0 25px 0;
}

.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}

.button {
    /* width: 100%; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140.62%;
    color: #000000;
    margin-top: 80px;
    margin-bottom: 115px;
}

@media only screen and (max-width: 1100px) {
    .title {
        margin-top: 40px;
    }

    .botImage {
        margin-top: 40px;
        background-position: right top;
    }

    .button {
        margin-top: 60px;
        margin-bottom: 70px;
    }
}

@media only screen and (max-width: 650px) {
    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 58px;
        color: #FFFFFF;
        margin: 40px 0 20px 0;
    }

    .leftContainer {
        width: 100%;
        min-width: 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .botImage {
        display: none;
    }

    .button {
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

@keyframes floatUpDown {
	0%, 100% {
		transform: translateY(0) rotate(0);
	}

	50% {
		transform: translateY(-20px) rotate(-3deg);
	}
}