.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerTitle {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: #FFFFFF;
}

.headerControls {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrowIcon {
    width: 35px;
    margin-left: 10px;
    stroke: #3A3535;
    fill: white;
    cursor: pointer;
    transition: stroke 0.15s;
}

.arrowIcon:hover {
    stroke: var(--c-orange)
}

.viewAllLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    transition: color 0.15s;
    cursor: pointer;
    margin: 0 10px 0 0;
}

.viewAllLabel:hover {
    color: var(--c-orange);
}

.itemContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 25px;
    overflow-y: auto;
}

.itemContainer::-webkit-scrollbar-thumb {
	display: none;
}

.itemContainerLoading {
    filter: blur(25px);
}

.loadingContainer {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

.loadingText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 22px;
    color: var(--c-orange);
    transition: color 0.15s;
    cursor: pointer;
}

.loader {
    width: 26px;
    margin-right: 15px;
}

@media only screen and (min-width: 1356px) {
    .arrowContainer {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .headerTitle {
        font-size: 28px;
        line-height: 32px;
    }

    .arrowIcon {
        width: 32px;
    }

    .viewAllLabel {
        display: none;
    }

    .headerTitle span {
        display: none;
    }

    .loadingText {
        font-size: 22px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 500px) {
    .headerTitle {
        font-size: 24px;
        line-height: 26px;
    }

    .arrowIcon {
        width: 30px;
    }

    .loadingText {
        font-size: 20px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 300px) {
    .headerTitle {
        font-size: 16px;
        line-height: 20px;
    }

    .arrowIcon {
        width: 25px;
    }

    .loadingText {
        font-size: 18px;
        line-height: 22px;
    }
}