.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 32px);
    height: 48px;
    background: #ffffff66;
    border-radius: 10px;
    padding: 0 16px;
    cursor: pointer;
    transition: border-radius, background-color 0.15s;
}

.inputDisabled {
    cursor: default;
}

.inputOpen {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #ffffff40;
}

.value {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.arrowIcon {
    width: 20px;
    height: 20px;
    fill: #FFFFFF;
    transition: transform 0.2s;
}

.inputDisabled .arrowIcon {
    fill: #b3b3b3;
}

.arrowIconOpen {
    transform: rotate(180deg);
}

.cancelOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.menuContainer {
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    top: 100%;
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.menuItem {
    width: calc(100% - 32px);
    padding: 14px 16px;
    border-bottom: 1px solid #FFFFFF10;
    background: #6f6e80;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    cursor: pointer;
}

.menuItem:hover {
    background: #858499;
}