.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.blockContainer {
    width: calc(100% - 68px);
    background: 
        linear-gradient(
            180deg, 
            rgba(62, 39, 158, 0.3) 0%, 
            rgba(17, 33, 107, 0.3) 100%
        );
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: var(--b-radius-lg);
    padding: 20px 34px;
    margin-bottom: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.statColumns {
    display: grid;
    grid-template-columns: 6fr 6fr 7fr;
}

.amountValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: var(--c-orange);
    margin: 0 8px 0 0;
}

.amountLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
    margin: 0;
}

.secondaryValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
    margin: 24px 0 5px 0;
}

.secondaryLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 5px 0 10px 0;
}

.dropdown {
    margin: 25px 0 20px 0;
}

.claimButton {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.messageOverlayContainer {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.messageOverlayText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #FFFFFF;
}

.messageOverlayButton {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 10px 0 0 0;
    height: 44px;
    width: 200px;
}

.loader {
    width: 24px;
    margin-right: 10px;
}

.blurred {
    filter: blur(6px) opacity(0.25)
}