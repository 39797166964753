.container {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    /* border: 1px solid #323240; */
    border-radius: var(--b-radius-lg);
    min-width: min(calc(100vw - 40px), 400px);
    margin-bottom: 10px;
    backdrop-filter: blur(20px);
    background: var(--c-panel);
}

.jackpotItemImage {
    margin-top: 57px;
    position: absolute;
}

.botItemImage {
    max-width: 70%;
    margin-top: 55px;
    position: absolute;
}

.statsContainer {
    position: relative;
    width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 35px;
    height: 550px;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0 0 22px 0;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 42px;
    width: max-content;
    cursor: pointer;
}

.linkIcon {
    width: 38px;
    height: 38px;
    margin-right: 17px;
    transition: transform 0.15s ease;
}

.linkLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.linkContainer:hover .linkIcon {
    transform: translate(2px, 0);
}

.linkContainer:hover .linkLabel {
    color: var(--c-orange);
}

@media only screen and (max-width: 500px) {
    .container {
        min-width: calc(100vw / 0.9 - 45px / 0.9);
        zoom: 0.9;
    }
}

@media only screen and (max-width: 350px) {
    .container {
        min-width: calc(100vw / 0.8 - 25px / 0.8);
        zoom: 0.8;
    }
}