.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.panelContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: var(--b-radius-lg);
    height: 460px;
}

.blurred:after {
    content: '';
    background: rgba(0 0 0 / 20%);
    position: absolute;
    inset: 0;
    backdrop-filter: blur(10px);

}

.mainPanel {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 460px;
    background: 
        linear-gradient(
            180deg, 
            #1C163B 0%, 
            #10142B 100%
        );
    border-radius: var(--b-radius-lg);
}

.mainPanelRed {
    background: 
    linear-gradient(
        180deg, 
        #2C1320 0%, 
        #1F1127 100%
    );
}

.overlayPanel {
    left: -30px;
    z-index: 0;
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 460px;
    background: 
        linear-gradient(
            180deg, 
            #1C163B 0%, 
            #10142B 100%
        );
    border-radius: var(--b-radius-lg);
    transition: all 0.15s ease-in-out;
}

.overlayPanelRed {
    background: 
    linear-gradient(
        180deg, 
        #2C1320 0%, 
        #1F1127 100%
    );
}

.overlayPanelOpen {
    left: 0;
    opacity: 1;
    z-index: 1;
}

.messageOverlayContainer {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.messageOverlayText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #FFFFFF;
}

.messageOverlayButton {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    background: #251A54;
    color: #FFFFFF;
    margin: 30px 0 0 0;
    height: 44px;
    width: 200px;
}

.messageOverlayButtonRed {
    background: #501461;
}

.loader {
    width: 24px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .container {
        zoom: 0.9;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        zoom: 0.8;
    }
}

@media only screen and (max-width: 400px) {
    .container {
        zoom: 0.7;
    }
}

@media only screen and (max-width: 370px) {
    .container {
        zoom: 0.6;
    }
}

@media only screen and (max-width: 300px) {
    .container {
        zoom: 0.5;
    }
}