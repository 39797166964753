.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background: var(--c-orange);
    border-radius: var(--b-radius-md);
    cursor: pointer;
    transition: filter 0.15s, box-shadow .3s ease-out;
    height: 48px;
    border: solid 1px rgba(255 255 255 / 25%);
    /* box-shadow: inset 0 0 8px 1px transparent; */
    padding: 0 32px;
    position: relative;
    overflow: hidden;
}

.pressed {
    filter: contrast(1.2) brightness(.8);
    border-color: rgba(255 255 255 / 15%);
    border-bottom-color: rgba(255 255 255 / 60%);

    .buttonHoverDot {
        display: none;
    }

    .text {
        transform: translateY(1px);
    }
}

.buttonHoverDot {
    --radius: 80px;
    position: absolute;
    left: calc(var(--radius) * -1);
    top: calc(var(--radius) * -1);
    width: calc(var(--radius) * 2);
    aspect-ratio: 1;
    background: radial-gradient(rgba(255 255 255 / 50%) 0%, transparent var(--radius));
    border-radius: 50%;
    pointer-events: none;
    transition: scale .2s ease-in-out;
}

.button:not(.pressed):hover {
    box-shadow: inset 0 0 15px 0 rgba(255 255 255 / 20%);
    /* filter: contrast(1.2); */
    border-top-color: rgba(255 255 255 / 60%);

    .text {
        opacity: 1;
    }
}

.hidden {
    scale: 0;
}

.buttonDisabled {
    filter: brightness(0.6);
    cursor: default;
}

.text {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 1px 0 0 0;
    pointer-events: none;
    position: relative;
    opacity: .7;
    transition: text-shadow .2s ease, opacity .2s;
}

.loader {
    width: 21px;
    margin-right: 10px;
}