.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;
    height: 58px;
    width: 30%;
    max-width: 240px;
    min-width: 150px;
}

.item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.15s;
}

.item:hover {
    background: rgba(235, 235, 255, 0.05);
}

.itemDisabled {
    background: #0e0e1196;
    cursor: default;
}

.itemDisabled:hover {
    background: #0e0e1196;
}

.icon {
    width: 16px;
    height: 16px;
}

.iconDisabled {
    opacity: 0.6;
}