.container {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: 
        linear-gradient(
            180deg, 
            #1C163B 0%, 
            #10142D 100%
        );
    border-radius: var(--b-radius-lg);
}

.upperSpacer {
    width: 100%;
    margin-bottom: 20px;
}

.midSpacer {
    width: 100%;
    margin: 15px 0 10px 0;
}

.titleBarContainer {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 40px;
}

.closeIcon {
    stroke: white;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: fill 0.15s;
}

.closeIcon:hover {
    stroke: rgba(255, 255, 255, 0.7);
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.row {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 40px;
    margin-top: 5px;
}

.label {
    width: calc(100% - 80px);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #A2A1AE;
    padding: 0 40px;
    margin: 7px 0;
}

.toggleButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #2F2C4A;
    border-radius: var(--b-radius-md);
    height: 40px;
    margin-right: 15px;
    cursor: pointer;
    transition: background 0.15s;
}

.toggleButtonActive {
    background: #32256A;
    border-radius: var(--b-radius-md);
}

.input {
    width: 80px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #2F2C4A;
    border: 1px solid var(--c-orange);
    border-radius: var(--b-radius-md);
    height: 38px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 0 10px;
}

.endLabel {
    width: 33px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.buttonContainer {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    padding: 0 30px;
}

.button {
    height: 50px;
    padding: 0 50px;
    margin: 12px 10px 30px 10px;
    background: #251A54;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.secondaryButton {
    background: transparent;
    border: 1px solid #382c6b;
    transition: border 0.15s;
}

.secondaryButton:hover {
    border: 1px solid #55469a;
}

@media only screen and (max-width: 570px) {
    .container {
        width: 100%;
    }
}