.container {
    flex: auto 1;
    display: flex;
    flex-direction: column;
}

.header {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 35px 44px 30px 44px;
}

.title {
    flex: 1 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.headerIcon {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.8;
}

.fieldContainer {
    margin: 15px 44px 0 44px;
}

.inputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.balanceRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.lowerRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-top: 10px;
}

.inputLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #A2A1AE;
    margin: 0;
}

.input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #FFFFFF;
    background: none;
    border: none;
    outline: none;
    text-align: right;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgb(255, 255, 255, 0.6);
    margin: 0;
}

.labelHighlight {
    opacity: 1;
    color: #EBC86D;
    cursor: pointer;
}

.secondaryLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff99;
    margin: 0;
}

.secondaryValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.stakeButton {
    width: calc(100% - 88px);
    background: #251A54;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 58px;
    color: #FFFFFF;
    transition: background-color 0.15s;
    margin: 0 44px 34px 44px;
}

.stakeButtonRed {
    background: #501461;
}

.spacer {
    margin: 5px 0 0 0;
}

p .labelHighlight {
    margin-left: 5px;
}