.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;
    backdrop-filter: blur(14px);
}

.backdropHidden {
    opacity: 0;
}

.container {
    box-shadow: 0px 16px 50px 0px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0px 16px 50px 0px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 16px 50px 0px rgba(0,0,0,0.15);
    transition: 0.2s ease;
    margin-top: 0;
    opacity: 1;
    margin: 10px;
    max-width: calc(100% - 10px);
    max-height: calc(100% - 10px);
}

.containerHidden {
    opacity: 0;
    margin-top: -50px;
}

@media only screen and (max-width: 440px) {
    .container {
        zoom: 0.9;
    }
}

@media only screen and (max-width: 400px) {
    .container {
        zoom: 0.8;
    }
}

@media only screen and (max-width: 350px) {
    .container {
        zoom: 0.7;
    }
}

@media only screen and (max-width: 310px) {
    .container {
        zoom: 0.6;
    }
}