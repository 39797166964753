.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: 
        linear-gradient(
            180deg, 
            #1C163B 0%, 
            #10142D 100%
        );
    border-radius: var(--b-radius-lg);
}

.upperSpacer {
    width: 100%;
    margin-bottom: 20px;
}

.midSpacer {
    width: 100%;
    margin: 15px 0 10px 0;
}

.titleBarContainer {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 40px;
}

.closeIcon {
    stroke: white;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: fill 0.15s;
}

.closeIcon:hover {
    stroke: rgba(255, 255, 255, 0.7);
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.contentBox {
    max-width: 900px;
    width: calc(100% - 80px);
    height: 500px;
    overflow: auto;
    margin: 0px 40px 40px 40px;
}

.contentBox p {
    color: white;
    font-size: 15px;
}

.contentBox h1 {
    color: white;
    font-size: 18px;
}

.contentBox h2 {
    color: white;
    font-size: 16px;
}

.contentBox h3 {
    color: white;
    font-size: 16px;
}

.contentBox li {
    color: white;
    font-size: 15px;
}

.contentBox a {
    color: white;
    font-size: 15px;
}

@media only screen and (max-width: 350px) {
    body {
        zoom: 0.9;
    }
}