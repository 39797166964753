.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #27241F;
    cursor: pointer;
}

.iconContainer:hover {
    background: #D9B76D25;
}

.icon {
    width: 75%;
    height: 75%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sideMenuIconContainer {
    width: 27px;
    height: 27px;
}

.cancelOverlay {
    z-index: 110;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.menuContainer {
    z-index: 110;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    top: calc(100% + 10px);
    left: -55px;
    width: 220px;
    border-radius: 10px;
}

.sideMenuMenuContainer {
    left: -150px;
}

.menuItemCotainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 14px 16px;
    border-bottom: 1px solid #FFFFFF10;
    background: #27241F;
    cursor: pointer;
}

.menuItemCotainer:hover {
    background: #2c2923;
}

.menuItemText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.menuItemIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.spacer {
    width: 15px;
}