.container {
    flex: auto 1;
    display: flex;
    flex-direction: column;
}

.header {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 35px 44px 30px 44px;
}

.spacer {
    margin: 0 0 20px 0;
}

.title {
    flex: 1 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 0;
}

.headerIcon {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.headerIcon:hover {
    opacity: 0.8;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 44px 20px 44px;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0;
}

.viewContract {
    width: 280px;
    background: #251A54;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 58px;
    color: #FFFFFF;
    margin-right: 20px;
    transition: background-color 0.15s;
    margin: 0 44px 34px 44px;
}

.viewContractRed {
    background: #501461;
}

.viewContract:hover {
    background: #2e2459;
}

.viewContractRed:hover {
    background: #521d61;
}