.container {
    width: 100%;
    max-width: 1188px;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.leftContainer {
    flex: 0.96;
    margin-left: 50px;
    margin-right: 50px;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 50px;
}

.botsImage {
    width: 100%;
    margin-top: 35px;
    animation: floatUpDown var(--anim-duration-xl) ease-in-out infinite;
    transform-origin: 20% 80%;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 58px;
    color: #FFFFFF;
    margin: 0;
}

.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
}

.button {
    /* width: 100%; */
    padding: 0 2rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140.62%;
    color: #000000;
}

.roundInfoContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 68px);
    margin: 40px 0 30px 0;
    background: var(--c-panel);
    /* background: 
        linear-gradient(
            180deg, 
            rgba(255, 255, 255, 0) -24.42%, 
            rgba(255, 255, 255, 0.1) 100%
        ); */
    border-radius: var(--b-radius-md);
    padding: 34px;
    backdrop-filter: blur(10px);
    border: solid 1px var(--c-magenta);
    border-bottom-width: 3px;
    box-shadow: 0 5px 15px rgba(0 0 0 / 80%);
}

.currentPoolLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0;
}

.currentPoolValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: var(--c-orange);
    margin: 18px 0;
}

.poolTimerContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background: 
        linear-gradient(
            180deg, 
            rgba(255, 255, 255, 0) -24.42%, 
            rgba(255, 255, 255, 0.1) 100%
        ); */
    /* border-radius: var(--b-radius-md); */
    /* margin-top: 30px; */
    /* padding: 24px 0; */

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        height: 1px;
        background: linear-gradient(
            to right, 
            transparent,
            white,
            transparent
        );
    }
}

.poolTimerLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    /* margin: 0 0 20px 24px; */
}

.poolTimerValueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* padding: 0 9px; */
}

.poolTimerValueText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0 15px;
}

.poolTimerValueText span {
    display: inline-block;
    width: 23px;
    text-align: right;
}

.poolTimerWaitingText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--c-orange);
    margin: 0 15px;
}

.loader {
    width: 24px;
}


@media only screen and (max-width: 1200px) {
    .botsImage {
        margin-top: 0;
        max-width: 420px;
    }

    .container {
        margin-bottom: 60px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .rightContainer {
        margin-bottom: -120px;
        margin-right: 0;
    }

    .leftContainer {
        max-width: 600px;
    }
}


@media only screen and (max-width: 768px) {
    .container {
        margin-bottom: 40px;
    }

    .leftContainer {
        margin: 0;
    }

    .roundInfoContainer {
        width: calc(100% - 40px);
        padding: 20px;
    }

    .currentPoolValue {
        margin: 10px 0;
    }

    .poolTimerContainer {
        margin-top: 15px;
        padding: 20px 0px;
    }

    .poolTimerLabel {
        margin: 0 0 15px 19px;
    }

    .poolTimerValueContainer {
        padding: 0 5px;
    }
}

@keyframes floatUpDown {
	0%, 100% {
		transform: translateY(0) rotate(0);
	}

	50% {
		transform: translateY(-50px) rotate(-5deg);
	}
}