.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(16 16 18 / 80%);
    padding-top: 50px;
    backdrop-filter: blur(5px);
}

.mainSection {
    display: flex;
    flex-direction: row;
}

.leftContainer {
    width: 400px;
    margin-left: 100px;
    margin-right: 20px;
}

.rightContainer {
    width: 550px;
    margin-right: 100px;
}

.logoText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    margin: 0 0 42px 0;
}

.logoText span {
    color: var(--c-orange);
}

.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0 0 15px 0;
}

.signUpContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 24px 0 60px 0;
}

.input {
    background: #FFFFFF;
    border-radius: var(--b-radius-md);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    height: 46px;
    border: none;
    outline: none;
    margin-right: 8px;
    padding: 0 10px;
}

.button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    height: 48px;
    width: 150px;
}

.iconListContainer {
    display: flex;
    flex-direction: row;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #1E1F26;
    border-radius: var(--b-radius-md);
    margin-right: 14px;
    cursor: pointer;
    transition: background 0.15s;
}

.iconContainer:hover {
    background: #2d2f3a;
}

.icon {
    width: 60%;
}

.spacer {
    flex: 1;
}

.bottomBar {
    width: calc(100% - 200px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 100px;
    border-top: 1px solid #FFFFFF10;
    margin-top: 44px;
    height: 80px;
}

.bottomBarLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.copyright {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.tosLink {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    transition: color 0.15s;
    cursor: pointer;
    margin-left: 35px;
}

.tosLink:hover {
    color: var(--c-orange);
}

@media only screen and (max-width: 1200px) {
    .leftContainer {
        margin-left: 40px;
    }
    
    .rightContainer {
        margin-right: 40px;
    }

    .bottomBar {
        width: calc(100% - 80px);
        padding: 0 40px;
    }
}

@media only screen and (max-width: 768px) {
    .logoText {
        margin: 0 0 20px 0;
        font-size: 30px;
    }

    .description {
        text-align: justify;
        font-size: 14px;
    }

    .title {
        font-size: 20px;
    }

    .input {
        font-size: 15px;
        line-height: 22px;
    }

    .button {
        font-size: 15px;
        line-height: 22px;
        width: 120px;
    }

    .mainSection {
        flex-direction: column;
    }

    .leftContainer {
        width: calc(100% - 80px);
        margin-left: 40px;
    }
    
    .rightContainer {
        width: calc(100% - 80px);
        margin-top: 40px;
        margin-left: 40px;
    }

    .bottomBar {
        width: calc(100% - 80px);
        padding: 0 40px;
    }

    .iconContainer {
        width: 40px;
        height: 40px;
    }
}


@media only screen and (max-width: 600px) {
    .leftContainer {
        width: calc(100% - 40px);
        margin-left: 20px;
    }
    
    .rightContainer {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .bottomBar {
        height: max-content;
    }
    
    .bottomBarLinkContainer {
        flex-direction: column;
        align-items: flex-end;
        margin: 10px 0;
    }
    
    .iconContainer {
        width: 35px;
        height: 35px;
    }

    .tosLink {
        margin: 5px 0;
    }
}